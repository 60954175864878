<ion-content [fullscreen]="true" class="bg">
  <ion-grid class="color-white ion-no-padding h-100">
    <ion-row class="h-100">
      <ion-col size="7" sizeLg="7" sizeMd="6" sizeXs="12" class="ion-no-margin ion-no-padding ion-hide-sm-down">
        <div class="my-flex ion-align-items-center ion-justify-content-center ion-text-center my-flex-column h-100">
          <img src="/assets/logo/final_logo.svg" width="200" alt="">
          <h1 class="title">{{'Socielite' | uppercase}}</h1>
          <p class="subtitle">
            Building societies and empowering student communities
          </p>
        </div>
      </ion-col>
      <ion-col size="5" sizeLg="5" sizeMd="6" sizeXs="12"  class="ion-no-margin ion-no-padding filter">
        <div class="my-flex ion-align-items-center ion-justify-content-center ion-text-center my-flex-column h-100">
          <img src="/assets/logo/final_logo.svg" width="100" alt="">
          <h2 class="margin-top-0">Welcome back!</h2>
          <small>Please enter your details</small>
          <br><br><br>
          
          <div class="container">
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <div class="rounded-input-container ion-margin-vertical ion-padding-top">
                <ion-item [class.invalid]="isSubmitted && loginForm.get('email')?.invalid && loginForm.get('email')?.touched" lines="none">
                  <ion-input placeholder="University Email" type="text" formControlName="email">
                  </ion-input>
                </ion-item>
                <div class="error-message">
                  @if(isSubmitted && loginForm.get('email')?.invalid && loginForm.get('email')?.touched) {
                    @if(isSubmitted && loginForm.get('email')?.errors?.['required']) {
                      Email is required.
                    }
                    @if(isSubmitted && loginForm.get('email')?.errors?.['pattern']) {
                      Invalid Email Provided
                    }
                  }
                </div>
              </div>
            
              <div class="rounded-input-container ion-margin-top padding-top-16">
                <ion-item [class.invalid]="isSubmitted && loginForm.get('password')?.invalid && loginForm.get('password')?.touched" lines="none">
                  <ion-input placeholder="Password" [type]="showPassword ? 'text' : 'password'" formControlName="password">
                  </ion-input>
                  <ion-icon slot="end" [name]="showPassword ? 'eye-off' : 'eye'" (click)="togglePasswordVisibility()"></ion-icon>
                </ion-item>
                <div class="error-message">
                  @if(isSubmitted && loginForm.get('password')?.invalid && loginForm.get('password')?.touched) {
                    @if(loginForm.get('password')?.errors?.['required']) {
                      Password is required
                    }
                    @if(isSubmitted && loginForm.get('password')?.errors?.['minlength']) {
                      A minimum of 6 characters is required.
                    }
                  }
                </div>
              </div>
            
              <div class="my-flex ion-justify-content-between ion-align-items-center margin-top-8 ion-margin-bottom ion-padding-bottom">
                <ion-checkbox labelPlacement="end">Remember me</ion-checkbox>
                <ion-button size="small" fill="clear" class="ion-no-margin ion-no-padding" color="secondary" (click)="openForgotPasswordModal('popup')">
                  Forgot Password?
                </ion-button>
              </div>
              @if(errorMessage) {
                <div class="error-message">
                  {{errorMessage}}
                </div>
              }
              <div class="ion-margin-top ion-padding-top button-border">
                <div class="button-background">
                  <ion-button class="bg-clear w-100" shape="round" type="submit">
                    @if (isLoading) {
                      <ion-spinner name="dots"></ion-spinner>
                    } @else {
                      Sign in
                    }
                  </ion-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>