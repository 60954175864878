import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormBuilder, FormGroup,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { VerificationService } from 'src/app/helpers/services/verification.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';
import { StorageService } from 'src/app/helpers/services/storage.service';
import { ROLE } from 'src/app/helpers/constant/role';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
})
export class LoginPage implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = '';
  showPassword = false;
  isLoading: boolean = false;
  isSubmitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController,
    private verificationService: VerificationService,
    private toasterLoaderService: ToasterLoaderService,
    private storageService: StorageService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      rememberMe: [false],
    });
  }

  ngOnInit() {}

  onLogin() {
    this.errorMessage = '';
    this.isSubmitted = true;
      const { email, password } = this.loginForm.value;
    
      if (!this.loginForm.valid) {
        this.loginForm.markAllAsTouched();
        return;
      }
      this.isLoading = true;
      this.authService.login({ email, password }).subscribe((response: any) => {
        this.isLoading = false;
        this.isSubmitted = false;
        if (response.success) {
          const user = response.user;
          this.storageService.set('user', user);
          this.storageService.set('token', user?.token);
          this.storageService.set('role', user?.roles);
    
          if (user?.roles.includes(ROLE.SOCIELITEADMIN)) {
            this.router.navigate(['/socielite-admin/home']);
          } else if (user?.roles.includes(ROLE.UNIVERSITYADMIN)) {
            this.router.navigate(['/university-admin/dashboard']);
          } else {
            this.router.navigate(['/student/dashboard']);
          }
        } else {
          this.loginForm.markAllAsTouched();
          this.errorMessage = response.message;
        }
      });
  }
  

  async openForgotPasswordModal(type: 'modal'|'popup'){

    const modal = await this.modalController.create({
      component: ForgotPasswordComponent,
      cssClass: type === 'popup' ? 'popup-modal' : 'dark-bg-main',
      breakpoints: type === 'popup' ? [] : [0.55],
      initialBreakpoint: type === 'popup' ? undefined : .55,
      backdropDismiss: false 
    })
    modal.present();
  }

  logout() {
    this.authService.logout().subscribe((response:any) => {
      if (response.success) {
        this.toasterLoaderService.toastMessage('success', 'sucess');
      } else {
        this.toasterLoaderService.toastMessage('danger', 'danger')
      }
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
