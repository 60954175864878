import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../../login/login.page.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule]
})
export class ResetPasswordComponent  implements OnInit {

  resetPasswordForm: FormGroup;
  email: any;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  isLoading: boolean = false;
  errorMessage: string = '';
  isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toasterLoaderService: ToasterLoaderService,
    private authService: AuthService,
    private router: Router 
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((response:any) => {
      this.email = response.email;
    });
  }

  onSubmit() {
    this.errorMessage = '';
    this.isSubmitted = true;

    const { password, confirm_password } = this.resetPasswordForm.value;
    let email = this.email;

    if (!this.resetPasswordForm.valid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    if (password === confirm_password) {
      this.isLoading = true;
      this.authService.resetPassword({email, password, confirm_password}).subscribe((response:any) => {
        this.isSubmitted = false;
        this.isLoading = false;
        if (response.success) {
          this.toasterLoaderService.toastMessage(response.message, 'success');
          this.router.navigate(['auth/login']);
        } else {
          this.errorMessage = response.message;
        }
      })
    } else {
      this.isSubmitted = false;
      this.errorMessage = 'Passwords do not match, please try again';
    }
  }
  
  cancel() {
    this.router.navigate(['auth/login']);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
