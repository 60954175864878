<div class="my-flex ion-justify-content-end ion-align-items-center margin-10 cursor">
  <ion-icon name="close-outline" (click)="close()"></ion-icon>
</div>
<div class="h-100 my-flex ion-justify-content-center p-5">
  <form class="ion-padding-bottom" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="ion-text-center ion-margin-vertical ion-padding-vertical back">
      <img src="/assets/images/back.svg" alt="back-icon">
    </div>
    <h1 class="gradient-text ion-padding-bottom bolder">
      LET'S GET YOU BACK ONLINE.
    </h1>
    <div class="ion-margin-bottom">
      <div class="rounded-input-container ion-margin-vertical ion-padding-top">
        <ion-item lines="none" [class.invalid]="forgotPasswordForm.get('email')?.invalid && forgotPasswordForm.get('email')?.touched">
          <ion-input placeholder="Email" type="text" formControlName="email"></ion-input>
        </ion-item>
        <div class="error-message">
          @if(isSubmitted && forgotPasswordForm.get('email')?.invalid && forgotPasswordForm.get('email')?.touched) {
            @if(isSubmitted && forgotPasswordForm.get('email')?.errors?.['required']) {
              Email is required.
            }
            @if(isSubmitted && forgotPasswordForm.get('email')?.errors?.['pattern']) {
              Invalid Email Provided
            }
          }
        </div>
      </div>
    </div>
    <div [class]="errorMessage ? '' : 'ion-margin-bottom ion-padding-bottom'">
      <div class="rounded-input-container ion-margin-vertical ion-padding-top">
        <ion-item lines="none" [class.invalid]="forgotPasswordForm.get('confirm_email')?.invalid && forgotPasswordForm.get('confirm_email')?.touched">
          <ion-input placeholder="Confirm Email" type="text" formControlName="confirm_email"></ion-input>
        </ion-item>
        <div class="error-message">
          @if(isSubmitted && forgotPasswordForm.get('confirm_email')?.invalid && forgotPasswordForm.get('confirm_email')?.touched) {
            @if(isSubmitted && forgotPasswordForm.get('confirm_email')?.errors?.['required']) {
              Confirm Email is required.
            }
            @if(isSubmitted && forgotPasswordForm.get('confirm_email')?.errors?.['pattern']) {
              Invalid Email Provided
            }
          }
        </div>
      </div>
    </div>
    @if(errorMessage) {
      <div class="error-message max-width-400">
        {{errorMessage}}
      </div>
    }
    <div class="ion-margin-top ion-padding-top button-border">
      <div class="button-background">
        <ion-button class="bg-clear" shape="round" expand="full" type="submit">
          @if (isLoading) {
            <ion-spinner name="dots"></ion-spinner>
          } @else {
            Send a recovery password
          }
        </ion-button>
      </div>
    </div>
  </form>
</div>