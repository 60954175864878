import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';
import { VerificationService } from 'src/app/helpers/services/verification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule],
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent  implements OnInit {

  forgotPasswordForm: FormGroup;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  errorMessage: string = '';

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private toasterLoaderService: ToasterLoaderService,
    private verificationService: VerificationService,
    private authService: AuthService
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]],
      confirm_email: ['', [Validators.required, Validators.pattern(new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))]]
    });
  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  onSubmit(){
    this.errorMessage = '';
    this.isSubmitted = true;
    const { email, confirm_email } = this.forgotPasswordForm.value;

    if (!this.forgotPasswordForm.valid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    if(email !== confirm_email) {
      this.errorMessage = 'The emails provided do not match each other. Please try again.'
      console.log(this.errorMessage);
    } else {
      this.isLoading = true;
      this.authService.forgotPassword({email}).subscribe((response:any) => {
        this.isLoading = false;
        this.isSubmitted =  false;
        if (response.success) {
          this.toasterLoaderService.toastMessage(response.message, 'success');
          this.modalController.dismiss();
        } else {
        this.errorMessage = response.message;
        }
      })

    }
  }
}
