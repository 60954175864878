<ion-content [fullscreen]="true"  class="bg">
  <div class="padding-12 my-flex ion-justify-content-center ion-align-items-center height-webkit-fill">
    <div class="filter border-radius-12 padding-10">
      <div class="ion-padding">
        <div class="my-flex ion-justify-content-center ion-align-items-center">
          <img src="/assets/logo/final_logo.svg" width="200" alt="">
        </div>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()"> <!--[formGroup]="" (ngSubmit)=""-->
          <h1 class="mb-text-center ion-text-center margin-top-5 margin-bottom-5">
            RESET PASSWORD
          </h1>
          <h1 class="gradient-text mb-text-center ion-text-center">
            LET'S GET YOU BACK ONLINE.
          </h1>
          <div class="form-content-container">
            <div class="rounded-input-container ion-margin-vertical ion-padding-top">
              <ion-item lines="none" [class.invalid]="isSubmitted && resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched">
                <ion-input placeholder="New Password" [type]="showPassword ? 'text' : 'password'" formControlName="password"></ion-input>
                <ion-icon slot="end" [name]="showPassword ? 'eye-off' : 'eye'" (click)="togglePasswordVisibility()"></ion-icon>
              </ion-item>
              <div class="error-message">
                @if(isSubmitted && resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched) {
                  @if(resetPasswordForm.get('password')?.errors?.['required']) {
                    New Password is required
                  }
                  @if(isSubmitted && resetPasswordForm.get('password')?.errors?.['minlength']) {
                    A minimum of 6 characters is required.
                  }
                }
              </div>
            </div>
  
            <div class="rounded-input-container ion-margin-vertical">
              <ion-item lines="none" [class.invalid]="isSubmitted && resetPasswordForm.get('confirm_password')?.invalid && resetPasswordForm.get('confirm_password')?.touched">
                <ion-input placeholder="Confirm Password" [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirm_password"></ion-input>
                <ion-icon slot="end" [name]="showConfirmPassword ? 'eye-off' : 'eye'" (click)="toggleConfirmPasswordVisibility()"></ion-icon>
              </ion-item>
              <div class="error-message">
                @if(isSubmitted && resetPasswordForm.get('confirm_password')?.invalid && resetPasswordForm.get('confirm_password')?.touched) {
                  @if(resetPasswordForm.get('confirm_password')?.errors?.['required']) {
                    Confirm Password is required
                  }
                  @if(isSubmitted && resetPasswordForm.get('confirm_password')?.errors?.['minlength']) {
                    A minimum of 6 characters is required.
                  }
                }
              </div>
            </div>
            @if(errorMessage) {
              <div class="error-message">
                {{errorMessage}}
              </div>
            }
            <div class="ion-margin-top ion-padding-top">
              <div class="button-border">
                <div class="button-background">
                  <ion-button size="small" class="button-color" shape="round" expand="full" type="submit">SUBMIT</ion-button>
                </div>
              </div>
              <div class="ion-padding-top">
                <ion-button class="cancel-outline-btn button-color" shape="round" expand="full" fill="outline" (click)="cancel()">CANCEL</ion-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ion-content>
